.volvo-container {
  max-width: 700px;
  margin-bottom: 10rem;
}

/*@media only screen and (max-width: 600px) {
  .volvo-container {
    max-width: 100%;
    width: 100%;
  }

  img {
    width: 90%;
  }
}*/
h2 {
  font-size: x-large;
}
h3 {
  font-size: larger;
}

p {
  margin-bottom: 5px;
}

img {
  max-width: 700px;
}

.volvo-logo {
  margin: 2rem;
  padding: 2rem;
}

.tags-heading {
  margin-top: 3rem;
  font-size: 19px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 2rem;

  .tag {
    font-size: 12px;
    padding: 0.7rem;
    margin: 0 0.2rem 0 0.5rem;
    border-radius: 10px;
    border: 1px solid black;
    opacity: 0;
  }
}


.volvo-download {
  color: #2a609d;
  border: 1px solid #2a609d;
  border-radius: 10px;
  padding: 1rem;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    background-color: lightblue;
  }
}

.fade-in {
  opacity: 0;                     /* Element starts invisible */
  transform: translateY(20px);    /* Slightly moved down */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Smooth transition */
}

.fade-in-visible {
  opacity: 1;                     /* Element becomes fully visible */
  transform: translateY(0);       /* Moves to its original position */
}

.fade-item {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8); /* Start slightly smaller */
}

/* Fade-in animation from the center with a slight scale effect */
.fade-in-center {
  opacity: 1;
  visibility: visible;
  transform: scale(1); /* Scale to full size */
  animation: fadeInCenter 0.6s ease-out forwards; /* Trigger animation */

}

/* Keyframes for the fade-in center effect */
@keyframes fadeInCenter {
  0% {
    opacity: 0;
    transform: scale(0.8); /* Start slightly smaller */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* End at full size */
  }
}

.volvo-footer {
  margin-top: 5rem;
}
