@import './constants';
@import './vars';

// --- Flex ---
%center-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
%row {
    display: flex; 
    flex-direction: row;
  }
%column {
    display: flex; 
    flex-direction: column;
  }
%flex-align-end {
    align-items: flex-end;
}
%flex-align-start {
    align-items:flex-start;
}
%flex-justify-end {
    justify-content: flex-end;
}
%flex-justify-start {
    justify-content: flex-start;
}

%flex-justify-center {
    justify-content: center;
}

%flex-align-center {
    align-items: center;
}

// --- Padding ---   
// Horizontal
%pad-h-xs {
    padding-left: $pad-xs;
    padding-right: $pad-xs;
}
%pad-h-s {
    padding-left: $pad-s;
    padding-right: $pad-s;
}
%pad-h-m {
    padding-left: $pad-m;
    padding-right: $pad-m;
}
%pad-h-l {
    padding-left: $pad-l;
    padding-right: $pad-l;
}
%pad-h-xl {
    padding-left: $pad-xl;
    padding-right: $pad-xl;
}
%pad-h-xxl {
    padding-left: $pad-xxl;
    padding-right: $pad-xxl;
}

// Vertical
%pad-v-xs {
    padding-top: $pad-xs;
    padding-bottom: $pad-xs;
}
%pad-v-s {
    padding-top: $pad-s;
    padding-bottom: $pad-s;
}
%pad-v-m {
    padding-top: $pad-m;
    padding-bottom: $pad-m;
}
%pad-v-l {
    padding-top: $pad-l;
    padding-bottom: $pad-l;
}

// All 
%pad-s {
    padding: $pad-s;
}
%pad-m {
    padding: $pad-m;
}
%pad-l {
    padding: $pad-l;
}
%pad-xl {
    padding: $pad-xl;
}

// --- Margins --- 
// Vertical
%marg-v-xxs {
    margin-top: $mrg-xxs;
    margin-bottom: $mrg-xxs;
}

%marg-v-xxl {
    margin-top: $mrg-xxl;
    margin-bottom: $mrg-xxl;
}

%marg-v-xxxl {
    margin-top: $mrg-xxxl;
    margin-bottom: $mrg-xxxl;
}

// Horizontal
%marg-h-xxs {
    margin-left: $mrg-xxs;
    margin-right: $mrg-xxs;
}

%marg-h-xxl {
    margin-left: $mrg-xxl;
    margin-right: $mrg-xxl;
}

%marg-h-xxxl {
    margin-left: $mrg-xxxl;
    margin-right: $mrg-xxxl;
}


// All 
%mrg-s {
    margin: $mrg-s;
}
%mrg-m {
    margin: $mrg-m;
}
%mrg-xxl {
    margin: $mrg-xxl;
}
