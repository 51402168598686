@media only screen and (max-width: 600px) {
  img {
    width: auto;
  }
}

.tmc-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #001D67;
  height: 5rem;
  color: white;
  margin-bottom: 1rem;

  .tmc-header-logo {
    height: 3rem;
    padding: 1rem 1rem 1rem 1rem;
    font-weight: 400;
    font-size: 42px;
  }
  .header-h3 {
    padding-right: 1rem;
  }
}

.sensoric-description {
  padding: 3rem 20%;
  display: flex;
  justify-content: center;
}

.tmc-download-container {
  margin: 2rem;

  .tmc-download {
    color: #2a609d;
    border: 1px solid #2a609d;
    border-radius: 10px;
    padding: 1rem;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      background-color: lightblue;
    }
  }
}

.tmc-img {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.tmc-footer {
  // set maxwith to 70% on larger screens
  max-width: 70%;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    max-width: 80%;
  }

  @media screen and (max-width: 600px) {
      max-width: 100%;
  }

  .footer-link {
  }
}

.tmc-tag-heading {
  font-size: 19px;
}

.tmc-tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;

  .tmc-tag {
    font-size: 12px;
    padding: 1rem;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    border: 1px solid black;
  }
}

.image-row {
  display: flex;
  justify-content: space-between;
}

.image-container {
  flex: 1;
  max-width: 33.33%;
  padding: 5px;
  box-sizing: border-box;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
