@import './vars';
@import './constants';
@import './extends';
@import './components/styles/header';
@import './components/styles/img-section';
@import './components/styles/note-section';
@import './components/styles/footer';
@import './components/styles/loader';
@import './components/styles/404';


  @font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Regular.woff2') format('woff2'),
    url('./assets/fonts/PPNeueMontreal-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Medium.woff2') format('woff2'),
    url('./assets/fonts/PPNeueMontreal-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Swear Display Medium';
  src: url('https://use.typekit.net/af/98fdf3/00000000000000007735e25c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/98fdf3/00000000000000007735e25c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/98fdf3/00000000000000007735e25c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('opentype');
  font-display: swap;
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}


body {
    background-color: $bg;
    font-family: 'PP Neue Montreal', sans-serif;
    @extend %center-center;
    margin: 0;
    padding: 0;
    color: $txt-color;
    line-height: 1.4;
    text-align: center;
    font-size: .96em;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeSpeed;
}
h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 1.43em;
    font-family: 'PP Neue Montreal', sans-serif;
}
p {
    margin: 0;
}
.header-logo {
    width: 230px;
    height: 52px;
    margin-bottom: 15px;
  }

  .btn {
  box-sizing: border-box;
  max-width: 222px;
  padding: 0.75rem 2.5rem;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  // align-self: stretch;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  border-radius: 2rem;
  height: 3rem;
  font-size: 12px;
  letter-spacing: 0.02em;
  font-weight: 500;
  line-height: 120%;
  cursor: pointer;
  white-space: nowrap;
  transition: transform 0.2s;

  &:hover {

transform: scale(1.025);
  }
}

.light {
  color: #000;
  background-color: #fff;
  border: 1px solid black;
}

@media screen and (min-width: 414px) {
  .btn {
    font-size: 14px;
  }
}
