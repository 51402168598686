@import './../../constants';
@import './../../vars';

.parfume-info {
  display: flex;
  align-items: center;
  flex-direction: column;

  .perfume-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: $mrg-xxxl;

    .perfume-bottle-container {
      position: relative;
      display: inline-block;

      img {
        width: min(calc(100vw - 32px), 356.5px);
        border-radius: 8%;
        aspect-ratio: 1;
      }

      .perfume-bottle-name {
        font-family: 'Swear Display Medium', serif;
        font-size: min(2.8vmin, 12px);
        font-weight: 600;
        font-style: italic;
        position: absolute;
        top: 66.7%;
        left: 52.5%;
        transform: rotate(-90deg);
        transform-origin: left top;
        text-align: left;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.9);
      }

      .perfume-bottle-by {
        font-size: min(1.6vmin, 7px);
        line-height: 100%;
        position: absolute;
        //font-weight: 400;
        top: 58.3%;

        left: 59.1%;
        transform: rotate(-90deg);
        transform-origin: left top;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .perfume-scentnotes-container {
      @extend %column;
      @extend %pad-m;
      justify-content: center;

      .perfume-scentnotes {
        display: flex;
        flex-direction: row;
        margin: 10px;

        .perfume-scentnotes-p {
          font-size: .80em;
          font-weight: 500;
          line-height: 2;
          padding-right: 10px;
          text-transform: uppercase;
        }

        .perfume-scentnotes-h3 {
          font-size: .96em;
        }

        @media only screen and (max-width: 440px) {
          .perfume-scentnotes-h3 {
            font-size: .90em;
          }
        }
      }

      @media only screen and (max-width: 540px) {
        .perfume-scentnotes {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .perfume-info-container {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 440px) {
    .perfume-info-container {
      margin-bottom: 0;
    }
  }
}