@import './../../constants';
@import './../../extends';

.header-logo-wrapper {
    @extend %center-center;
    @extend %column;
    .header-logo{
        padding-top: $pad-l;
    }
    .header-h1 {
        @extend %pad-h-xxl; 
        font-size: $fnt-m;
        margin-bottom: 15px;
    }
    @media only screen and (max-width: 600px) {
        .header-h1 {
            font-size: $fnt-l-mobile;
        }
    }
}