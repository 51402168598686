// --- Font Sizes ---
$fnt-s: 1em;
$fnt-m: 1.43em;
$fnt-l: 70em;
//-- Font Sizes Mobile--
$fnt-l-mobile: 1.25em; 

// --- Letter Spacing ---
$l-space-m: .5px;

// --- Margins --- 
$mrg-xxs: 2px;
$mrg-xs: 5px;
$mrg-s: 10px;
$mrg-m: 15px;
$mrg-l: 20px;
$mrg-xl: 25px;
$mrg-xxl: 30px;
$mrg-xxxl: 60px;

// --- Padding --- 
$pad-xxs: 2px;
$pad-xs: 5px;
$pad-s: 10px;
$pad-m: 15px;
$pad-l: 20px;
$pad-xl: 30px;
$pad-xxl: 40px;

