@import './../../extends';
@import './../../constants';
@import './../../vars';

$max-img-width-mobile: min(calc(100vw - 32px), 356.5px);

.image-text-div {
  display: flex;
  align-items: center;
  flex-direction: column;

  .image-p {
    max-width: 650px;
    margin: 15px 30px;
    text-align: center;
  }

  .header-h1 {
    font-size: 1.43em;
    @extend %pad-h-l;
    margin-top: 30px;
    @media (max-width: 600px) {
      font-size: 1.25em;
    }
  }
}

.perfume-img-wrapper {
  @extend %pad-l;
  @extend %marg-v-xxl;
  @extend %center-center;
  flex-wrap: wrap;
  @extend %column;
  box-sizing: border-box;
  width: 100%;
  row-gap: 32px;

  p {
    padding-left: 25px;
    text-align: left;
  }

  .img-container {
    line-height: 0;
    overflow: hidden;
    margin: 0 1%;
    transition: .5s;
    border-radius: 4%;

    img {
      width: 650px;
      transition: .5s;
      object-fit: cover;
    }

    @media only screen and (max-width: 1000px) {
      img {
        width: 650px;
      }
    }
    @media only screen and (max-width: 700px) {
      img {
        width: $max-img-width-mobile;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .perfume-img-wrapper {
    flex-direction: column !important;
    margin: 0 !important;
    padding: 15px !important;
  }
}
