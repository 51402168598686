@import './../../extends';
@import './../../constants';
@import './../../vars';

.footer {
    @extend %center-center;
    @extend %column;
    margin-bottom: $mrg-xxxl;
    &-p {
        max-width: 650px;
        margin: $mrg-m $mrg-xxl;
    }
    &-p-mid{
        max-width: 650px;
        margin: 0 $mrg-xxl;
    }
    &-p-end {
        margin-top: $mrg-m;
        @extend %marg-h-xxl;
    }
    &-bttns {
        display: flex;
        align-items: inherit;
        margin-top: $mrg-xl;
        &-p {
            padding: 0 15px;
        }
    }
    @media only screen and (max-width: 600px) {
        &-bttns {
            box-sizing: border-box;
            position: fixed;
            justify-content: space-around;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            max-width: 100vw;
            padding: 5px 5px 10px 5px;
            background: #fff;
            &-p {
                display: none;
            }

            .btn {
                width: 100%;
                max-width: 46vw;
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .footer {
        margin: 30px 5px !important;
        &-p-mid {
            margin: 0 30px;
        }
        &-p-end {
            margin-bottom: 130px;
        }
    }
}